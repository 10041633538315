import { Fragment, useState, useEffect, useCallback } from "react";
import "./topic_content_component.scss";
import { useLocation } from "react-router-dom";
import images from "../../../../../utilities/images/images";
import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import {
  url_11,
  url_13,
  url_33,
  url_39,
  url_55,
  url_140,
  url_214,
} from "../../../../../custom_utilities/api_services";
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";
import userActivityActions from "../../../../../redux/user_activity/action";
import * as rdd from "react-device-detect";
import InfiniteScroll from "react-infinite-scroll-component";
import { setLiveVideoStreamDetails } from "../../../../../redux/live_video_streaming/LiveVideoStreamingSlice";
import {
  setWatchedVideoDetails,
  setNoteAddedVideoTime,
} from "../../../../../redux/watched_video_details/WatchedVideoDetailsSlice";

const TopicContentComponent = (props) => {
  let {
    history,
    userPreference,
    subjectData,
    topicData,
    startLoading,
    finishLoading,
    setVideoData,
    setRecordedVideoData,
    setNotesData,
    setLiveStreamData,
    setExerciseData,
    setLiveStreamVideoData,
    setLiveVideoStreamDetailsAction,
    setWatchedVideoDetailsAction,
    setNoteAddedVideoTimeAction,
  } = props;

  let { courseDetails } = userPreference;
  const location = useLocation();
  let crsID = location.state;

  const initailFeatureTypeCheck = (topicData) => {
    const featureMapping = {
      is_video_available: 1,
      is_live_class_available: 2,
      is_excersice_available: 3,
      is_dpp_available: 4,
      is_notes_available: 5,
      is_additional_video_available: 6,
    };

    for (const [key, value] of Object.entries(featureMapping)) {
      if (topicData[key]) {
        return value;
      }
    }
  };

  const [state, setState] = useState({
    featureType: initailFeatureTypeCheck(topicData),
    videosData: [],
    additionalVideosData: [],
    facultyData: {},
    liveVideoData: [],
    liveClassesData: {},
    exercisesData: {},
    notesData: {},
    pageNumber: 1,
    recordedPageNumber: 1,
    currentFacultyName: {},
    additionalFacultyData: {},
    additionalCurrentFacultyName: {},
  });
  // state?.additionalCurrentFacultyName?.user_id

  const getVideosData = useCallback(async () => {
    let data = {
      search: "",
      course_id: location.state ? crsID : courseDetails[0].course_id,
      subject_id: subjectData?.subjectId,
      topic_id: topicData.topicId,
      faculty_id: state?.currentFacultyName?.user_id,
    };
    startLoading();
    try {
      const response = await axios.get(
        `${url_11}?page=1&search=${data.search}&course_id=${data.course_id}&subject_id=${data.subject_id}&topic_id=${data.topic_id}&faculty_id=${data.faculty_id}`
      );
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          videosData: [...response.data.data.VideoList.data],
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    courseDetails,
    crsID,
    finishLoading,
    location.state,
    startLoading,
    state?.currentFacultyName?.user_id,
    subjectData?.subjectId,
    topicData.topicId,
  ]);

  const getRecordedLiveClassData = useCallback(
    async (page = 1) => {
      let data = {
        subject_id: subjectData?.subjectId,
        topic_id: topicData.topicId,
        type: "live",
        page: page,
      };
      startLoading();
      try {
        const response = await axios.get(
          `${url_214}/?subject_id=${data.subject_id}&topic_id=${data.topic_id}&type=live&page=${data.page}`
        );
        if (response.status === 200) {
          setState((prevState) => ({
            ...prevState,
            liveVideoData: response.data,
          }));
          finishLoading();
        } else {
          alert(response.data.message);
          finishLoading();
        }
      } catch (error) {
        finishLoading();
        console.log(error);
      }
    },
    [startLoading, finishLoading, subjectData?.subjectId, topicData.topicId]
  );

  const getFacultyData = useCallback(async () => {
    let data = {
      search: "",
      course_id: location.state ? crsID : courseDetails[0].course_id,
      subject_id: subjectData?.subjectId,
      topic_id: topicData.topicId,
    };
    try {
      const response = await axios.get(
        `${url_140}?page=1&search=${data.search}&course_id=${data.course_id}&subject_id=${data.subject_id}&topic_id=${data.topic_id}`
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          facultyData: response.data.data,
          currentFacultyName: response.data.data.VideoList[0],
        }));
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    courseDetails,
    crsID,
    location.state,
    subjectData?.subjectId,
    topicData.topicId,
  ]);

  const getAdditionalFacultyData = useCallback(async () => {
    let data = {
      search: "",
      course_id: location.state ? crsID : courseDetails[0].course_id,
      subject_id: subjectData?.subjectId,
      topic_id: topicData.topicId,
      type: 2,
    };
    try {
      const response = await axios.get(
        `${url_140}?page=1&search=${data.search}&course_id=${data.course_id}&subject_id=${data.subject_id}&topic_id=${data.topic_id}&type=${data.type}`
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          additionalFacultyData: response.data.data,
          additionalCurrentFacultyName: response.data.data.VideoList[0],
        }));
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    courseDetails,
    crsID,
    location.state,
    subjectData?.subjectId,
    topicData.topicId,
  ]);

  /////////////Live classess////////////////
  // classes_type=>1 =>for upcoming classes
  // classes_type=>0 =>for previous classes

  ///////////Exercise///////////////////
  const getExerciseList = useCallback(async () => {
    let requestPayload = {
      course_id: location.state ? crsID : courseDetails[0].course_id,
      topic_id: topicData.topicId,
      page: 0,
      type: "Exercise",
      // is_attempt: true,
    };
    startLoading();
    try {
      const response = await axios.post(url_33, requestPayload);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          exercisesData: response.data.data,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  }, [
    courseDetails,
    crsID,
    location.state,
    topicData.topicId,
    finishLoading,
    startLoading,
  ]);

  ////////////Notes////////////////////
  const getNotesData = useCallback(async () => {
    let data = {
      search: "",
      course_id: location.state ? crsID : courseDetails[0].course_id,
      subject_id: subjectData?.subjectId,
      topic_id: topicData.topicId,
    };

    startLoading();
    try {
      const response = await axios.get(
        `${url_13}?page=1&search=${data.search}&course_id=${data.course_id}&subject_id=${data.subject_id}&topic_id=${data.topic_id}`
      );

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          notesData: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  }, [
    courseDetails,
    crsID,
    location.state,
    subjectData?.subjectId,
    topicData.topicId,
    startLoading,
    finishLoading,
  ]);

  ////// DPP //////////

  const getDppList = useCallback(async () => {
    let data = {
      course_id: location.state ? crsID : courseDetails[0].course_id,
      type: "DPP",
      topic_id: topicData.topicId,
      page: 0,
    };

    startLoading();
    try {
      const response = await axios.post(url_33, data);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          dppCollections: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  }, [
    courseDetails,
    crsID,
    location.state,
    topicData.topicId,
    startLoading,
    finishLoading,
  ]);

  const getInitialRenderData = useCallback(() => {
    let featureType = state.featureType;

    switch (featureType) {
      case 1:
        getFacultyData();
        break;
      case 2:
        getRecordedLiveClassData();
        break;
      case 3:
        getExerciseList();
        break;
      case 4:
        getNotesData();
        break;
      case 5:
        getDppList();
        break;
      case 6:
        getAdditionalFacultyData();
        break;
      default:
        break;
    }
  }, [
    getAdditionalFacultyData,
    getDppList,
    getExerciseList,
    getFacultyData,
    getNotesData,
    getRecordedLiveClassData,
    state.featureType,
  ]);

  useEffect(() => {
    getFacultyData();
    getAdditionalFacultyData();
    getInitialRenderData();
    getRecordedLiveClassData();
  }, [
    getAdditionalFacultyData,
    getFacultyData,
    getInitialRenderData,
    getRecordedLiveClassData,
  ]);

  useEffect(() => {
    getVideosData();
  }, [state.currentFacultyName, getVideosData]);

  const handleChangeFaculty = (element) => {
    setState((prevState) => ({
      ...prevState,
      currentFacultyName: element,
    }));
    finishLoading();
  };

  const renderFetaures = () => {
    let featureType = state.featureType;
    switch (featureType) {
      case 1:
        return renderVideosCollectionView(); //videos
      case 2:
        return renderLiveClassCollectionView(); //live
      case 3:
        return renderExerciseCollectionView(); //exercise
      case 4:
        return renderNotesCollectionView(); //notes
      case 5:
        return renderDppCollectionView(); //dpp
      case 6:
        return renderAdditionalVideosCollectionView(); //additional videos
      default:
        return null;
    }
  };

  const setLiveClassRecord = async (element) => {
    let zoomId = decrypt_functionality(element.zoom_id);

    let data = {
      device_id: rdd.fullBrowserVersion,
      device_type: rdd.browserName,
      device_name: rdd.osName,
      zoom_id: zoomId,
      type: "Web",
    };
    startLoading();
    try {
      const response = await axios.post(url_55, data);
      if (response.data.status === 200) {
        data.redirectedFrom = "activity";
        setLiveStreamData(element);
        history.push("/zoom");

        finishLoading();
      } else {
        finishLoading();
        alert(response.data.message);
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const decrypt_functionality = (val) => {
    const CryptoJS = require("crypto-js");

    let encrypted = val;
    let key = "0CiuKpLCZbX7gBCyYTka4USxTnh7ssXKk8bJ6Y3TXPc=";

    encrypted = atob(encrypted);

    encrypted = JSON.parse(encrypted);

    const iv = CryptoJS.enc.Base64.parse(encrypted.iv);

    const value = encrypted.value;

    key = CryptoJS.enc.Base64.parse(key);

    var decrypted = CryptoJS.AES.decrypt(value, key, {
      iv: iv,
    });

    decrypted = decrypted.toString(CryptoJS.enc.Utf8);

    return decrypted.split("*")[1];
  };

  const handleRecordedWatchNow = async (element) => {
    setLiveVideoStreamDetailsAction({
      zoomId: element?.zoom_id || element?.video_crypt_id,
      isLive: false,
      live_class_id: "",
      normalVideos: false,
    });

    history.push({
      pathname: "/streaming",
      state: {
        zoomId: element?.zoom_id || element?.video_crypt_id,
        isLive: false,
      },
    });

    setRecordedVideoData(element);
  };

  const handleStoreDataInStorage = (type, data) => {
    if (type === "exercise") {
      if (!data.is_attempt) {
        data.redirectedFrom = "activity";
        data.exerciseType = "Exercise";
        setExerciseData(data);
        history.push({
          pathname: "/evaluation",
          exact: true,
          state: {
            type: "exercise",
            topicData: topicData,
            subjectData: subjectData,
          },
        });
      } else {
        data.redirectedFrom = "activity";
        data.exerciseType = "Exercise";
        setExerciseData(data);
        history.push({
          pathname: "/evaluation1",
          exact: true,
          state: { type: "exercise" },
        });
      }
    } else if (type === "dpp") {
      if (!data.is_attempt) {
        data.redirectedFrom = "activity";
        data.exerciseType = "Dpp";
        setExerciseData(data);
        history.push({
          pathname: "/evaluation",
          exact: true,
          state: {
            type: "dpp",
            topicData: topicData,
            subjectData: subjectData,
          },
        });
      } else {
        data.redirectedFrom = "activity";
        data.exerciseType = "Dpp";
        setExerciseData(data);
        history.push({
          pathname: "/evaluation",
          exact: true,
          state: { type: "dpp" },
        });
      }
    } else {
      if (data.is_paid === 0) {
        // 0 means paid , 1 means not paid
        switch (type) {
          case "videos":
            data.redirectedFrom = "activity";
            setLiveVideoStreamDetailsAction({
              zoomId: data?.zoom_id || data?.video_crypt_id,
              isLive: false,
              live_class_id: "",
              normalVideos: true,
            });

            setVideoData(data);
            setWatchedVideoDetailsAction(data);
            setNoteAddedVideoTimeAction(0);
            history.push("/videos");
            break;
          case "live_class":
            setLiveClassRecord(data);
            break;
          case "live_class_video":
            data.redirectedFrom = "activity";
            setLiveStreamVideoData(data);
            history.push("/live_stream_video");
            break;
          case "exercise":
            break;
          case "notes":
            data.redirectedFrom = "activity";
            setNotesData(data);
            history.push("/notes");
            break;
          default:
            break;
        }
      } else {
        alert("Please purchase subscription");
        history.push("/store");
      }
    }
  };

  const handleBookmarkVideo = async (videoDetails) => {
    let videoList = [...state.videosData];

    let videoIndex = videoList.findIndex(
      (element) => element.video_id === videoDetails.video_id
    );

    if (videoIndex !== -1) {
      videoList[videoIndex].IsSaved = !videoList[videoIndex].IsSaved;

      setState((prevState) => ({
        ...prevState,
        videosData: videoList,
      }));
    } else {
      return;
    }

    let data = {
      type: 4,
      reference_id: videoDetails.video_id,
      status: videoList[videoIndex].IsSaved ? 1 : 0,
    };

    try {
      await axios.post(url_39, data);
    } catch (error) {
      console.log(error);
    }
  };

  const renderVideosCollectionView = () => {
    return (
      <Fragment>
        <div className="videosCollectionView_wrapper">
          <div className="videosCollectionView_inner_wrapper">
            <div className="sec_2_wrapper">
              <div className="faculty_tabs">
                {!isEmpty(state.facultyData) &&
                state.facultyData.VideoList.length
                  ? state.facultyData.VideoList.map((element, index) => (
                      <div key={index} className="single_select_facultyName">
                        <input
                          type="radio"
                          id={`facultyName_${element.name}`}
                          name="facultyName"
                          value={element.name}
                          checked={
                            state.currentFacultyName.name === element.name
                          }
                          onChange={() => handleChangeFaculty(element)}
                        />
                        <label htmlFor={`facultyName_${element.name}`}>
                          <span>
                            <img src={element.thumnail} alt="icon" />
                          </span>
                          {element.name}
                        </label>
                      </div>
                    ))
                  : null}
              </div>

              <div className="sec_2_inner_wrapper">
                {state.videosData.length ? (
                  <div
                    id="parentScrollDiv"
                    className="videosListBox"
                    style={{
                      height: "43vh",
                      overflowY: "auto",
                      padding: "5px",
                    }}
                  >
                    <InfiniteScroll
                      dataLength={state.videosData.length}
                      next={fetchData}
                      hasMore={true}
                      scrollableTarget="parentScrollDiv"
                    >
                      <div className="videos_collection_wrapper">
                        {state.videosData.map((element, index) => (
                          <div key={index} className="single_video_wrapper">
                            <div
                              className="left_side_content_wrapper"
                              onClick={() =>
                                handleStoreDataInStorage("videos", element)
                              }
                            >
                              <div className="image_wrapper">
                                <img src={element.thumnail} alt="thumbnail" />
                              </div>
                            </div>
                            <div className="right_side_content_wrappers">
                              <div className="right_side_content_wrappers_top">
                                <div
                                  className="text_content_wrapper_1"
                                  onClick={() =>
                                    handleStoreDataInStorage("videos", element)
                                  }
                                >
                                  <p className="text_content_1">
                                    {element.video_title}
                                  </p>
                                </div>
                                <div className="image_wrapper">
                                  {element.IsSaved ? (
                                    <img
                                      src={images.user_activity_bookmark_on}
                                      alt="Bookmarked"
                                      onClick={() =>
                                        handleBookmarkVideo(element)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={images.user_activity_bookmark_off}
                                      alt="Not Bookmarked"
                                      onClick={() =>
                                        handleBookmarkVideo(element)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  by {element.name}
                                </p>
                                <div className="label_content_wrapper">
                                  <label>
                                    <span>
                                      <img
                                        src={images.user_activity_2}
                                        alt="rating"
                                      />
                                    </span>
                                    <span>{element.video_rating}</span>
                                  </label>
                                </div>
                              </div>
                              <div className="sub_content_wrapper">
                                <div className="right_side_content_wrappers">
                                  <button
                                    style={{ marginRight: "4px" }}
                                    onClick={() =>
                                      handleStoreDataInStorage(
                                        "videos",
                                        element
                                      )
                                    }
                                  >
                                    Watch Now
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </InfiniteScroll>
                  </div>
                ) : (
                  <div
                    className="no_data_found_wrapper"
                    style={{ textAlign: "center", margin: "auto" }}
                  >
                    <img src={images.new_m2_video_ntF} alt="No Videos" />
                    <p>There is no video yet!</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderAdditionalVideosCollectionView = () => {
    return (
      <Fragment>
        <div className="videosCollectionView_wrapper">
          <div className="videosCollectionView_inner_wrapper">
            <div className="sec_2_wrapper">
              <div className="faculty_tabs">
                {!isEmpty(state.additionalFacultyData) &&
                state.additionalFacultyData.VideoList.length ? (
                  state.additionalFacultyData.VideoList.map(
                    (element, index) => {
                      return state.additionalFacultyData.VideoList.length >
                        1 ? (
                        <div key={index} className="single_select_facultyName">
                          <input
                            type="radio"
                            id={`facultyName_${element.name}`}
                            name="facultyName"
                            value={element.name}
                            checked={
                              state.currentFacultyName.name === element.name
                            }
                            onChange={() => handleChangeFaculty(element)}
                          />
                          <label htmlFor={`facultyName_${element.name}`}>
                            <span>
                              <img src={element.thumnail} alt="icon" />
                            </span>
                            {element.name}
                          </label>
                        </div>
                      ) : null;
                    }
                  )
                ) : (
                  <div
                    className="no_data_found_wrapper"
                    style={{ textAlign: "center", margin: "auto" }}
                  >
                    <img src={images.new_m2_video_ntF} alt="No Videos" />
                    <p>There is no video yet!</p>
                  </div>
                )}
              </div>

              <div className="sec_2_inner_wrapper">
                {state.additionalVideosData.length ? (
                  <div
                    id="parentScrollDiv"
                    className="videosListBox"
                    style={{
                      height: "60vh",
                      overflow: "auto",
                      overflowY: "scroll",
                    }}
                  >
                    <InfiniteScroll
                      dataLength={state.additionalVideosData.length}
                      next={fetchData}
                      hasMore={true}
                      scrollableTarget="parentScrollDiv"
                    >
                      <div className="videos_collection_wrapper">
                        {state.additionalVideosData.length ? (
                          state.additionalVideosData.map((element, index) => (
                            <div key={index} className="single_video_wrapper">
                              <div
                                className="left_side_content_wrapper"
                                onClick={() =>
                                  handleStoreDataInStorage("videos", element)
                                }
                              >
                                <div className="image_wrapper">
                                  <img src={element.thumnail} alt="thumbnail" />
                                </div>
                              </div>
                              <div className="right_side_content_wrappers">
                                <div className="right_side_content_wrappers_top">
                                  <div
                                    className="text_content_wrapper_1"
                                    onClick={() =>
                                      handleStoreDataInStorage(
                                        "videos",
                                        element
                                      )
                                    }
                                  >
                                    <p className="text_content_1">
                                      {element.video_title}
                                    </p>
                                  </div>
                                </div>
                                <div className="text_content_wrapper_2">
                                  <p className="text_content_2">
                                    by {element.name}
                                  </p>
                                </div>
                                <div className="sub_content_wrapper">
                                  <div className="right_side_content_wrappers">
                                    <button
                                      onClick={() =>
                                        handleStoreDataInStorage(
                                          "videos",
                                          element
                                        )
                                      }
                                    >
                                      Watch Now
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div
                            className="no_data_found_wrapper"
                            style={{ textAlign: "center", margin: "auto" }}
                          >
                            <img
                              src={images.new_m2_video_ntF}
                              alt="ntf-image"
                            />
                            <p>There is no video yet!</p>
                          </div>
                        )}
                      </div>
                    </InfiniteScroll>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderLiveClassCollectionView = () => {
    return (
      <Fragment>
        <div className="liveClassCollectionView_wrapper">
          <div className="liveClassCollectionView_inner_wrapper">
            <div className="section_wrapper">
              <div className="section_inner_wrapper">
                <div className="tab_wrapper">
                  <div
                    className="tab-content custom_tab_content"
                    id="pills-tabContent"
                  >
                    <div
                      className="tab-pane fade show active custom_tab_pane"
                      id="pills-previous-class"
                      role="tabpanel"
                      aria-labelledby="pills-previous-class-tab"
                    >
                      <div className="previous_class_tab_wrapper">
                        <div className="outer_content_wrapper">
                          <div className="liveClasses_collection_wrapper">
                            {!isEmpty(state.liveVideoData.data) ? (
                              state.liveVideoData.data.length ? (
                                state.liveVideoData.data.map(
                                  (element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="single_liveClass_wrapper"
                                      >
                                        <div className="left_side_content_wrapper">
                                          {element.faculty_image ? (
                                            <div className="image_wrapper">
                                              <img
                                                src={element.faculty_image}
                                                alt="faculty"
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className="image_wrapper"
                                              style={{ padding: "30px" }}
                                            >
                                              <img
                                                src={
                                                  images.play_thumbnail_image
                                                }
                                                style={{ width: "50px" }}
                                                alt="faculty"
                                              />
                                            </div>
                                          )}
                                        </div>
                                        <div className="right_side_content_wrappers">
                                          <div className="text_content_wrapper_1">
                                            <p className="text_content_1">
                                              {element.meeting_topic}
                                            </p>
                                          </div>
                                          <div className="text_content_wrapper_2">
                                            <span>
                                              <p className="text_content_2">
                                                {`by ${element.faculty}`}
                                              </p>
                                            </span>
                                          </div>
                                          <div className="d-flex gap-2 align-items-center">
                                            <span>
                                              <img
                                                src={
                                                  images.user_activity_calendar
                                                }
                                                alt="calendar"
                                              />
                                              <p className="text_content_2">
                                                {`${element.meetdate}`}
                                              </p>
                                            </span>
                                            <span>
                                              <img
                                                src={images.user_activity_31}
                                                alt="clock"
                                              />
                                              <p className="text_content_2">
                                                {`${element.meeting_duration}`}
                                              </p>
                                            </span>
                                          </div>
                                          <div className="sub_content_wrapper">
                                            <div className="right_side_content_wrappers">
                                              <button
                                                className=""
                                                onClick={() =>
                                                  handleRecordedWatchNow(
                                                    element
                                                  )
                                                }
                                              >
                                                Watch now
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <div
                                  className="no_data_found_wrapper"
                                  style={{
                                    textAlign: "center",
                                    margin: "auto",
                                  }}
                                >
                                  <img
                                    src={images.new_m2_video_ntF}
                                    alt="m2-video"
                                  />
                                  <p>No Recoded class is available as of now</p>
                                </div>
                              )
                            ) : (
                              <div
                                className="no_data_found_wrapper"
                                style={{ textAlign: "center", margin: "auto" }}
                              >
                                <img
                                  src={images.new_m2_video_ntF}
                                  alt="ntf-image"
                                />
                                <p>No Recoded class is available as of now</p>
                              </div>
                            )}
                          </div>
                          {!isEmpty(state.liveVideoData.data) ? (
                            state.liveVideoData.data.length ? (
                              <div className="sec_3_wrapper">
                                <div className="sec_3_inner_wrapper">
                                  <div className="btn_wrapper">
                                    {state.recordedPageNumber > 1 && (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setState((prevState) => ({
                                            ...prevState,
                                            recordedPageNumber:
                                              prevState.recordedPageNumber - 1,
                                          }));
                                          getRecordedLiveClassData(
                                            state.recordedPageNumber - 1
                                          );
                                        }}
                                      >
                                        Prev
                                      </button>
                                    )}
                                  </div>
                                  <div className="btn_wrapper">
                                    {state.recordedPageNumber <
                                      state.liveVideoData.last_page && (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setState((prevState) => ({
                                            ...prevState,
                                            recordedPageNumber:
                                              prevState.recordedPageNumber + 1,
                                          }));
                                          getRecordedLiveClassData(
                                            state.recordedPageNumber + 1
                                          );
                                        }}
                                      >
                                        Next
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : null
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderExerciseCollectionView = () => {
    return (
      <Fragment>
        <div className="exerciseCollectionView_wrapper">
          <div className="exerciseCollectionView_inner_wrapper">
            <div className="sec_1_wrapper">
              <div className="options_wrapper">
                <ul
                  className="nav nav-pills custom_nav_pills"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item custom_nav_item" role="presentation">
                    <button
                      className="nav-link active custom_nav_link"
                      id="pills-upcoming-class-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-upcoming-class"
                      type="button"
                      role="tab"
                      aria-controls="pills-upcoming-class"
                      aria-selected="true"
                    >
                      Start Practice
                    </button>
                  </li>
                  <li className="nav-item custom_nav_item" role="presentation">
                    <button
                      className="nav-link custom_nav_link"
                      id="pills-previous-class-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-previous-class"
                      type="button"
                      role="tab"
                      aria-controls="pills-previous-class"
                      aria-selected="false"
                    >
                      Resume Practice
                    </button>
                  </li>
                </ul>
              </div>
              <div
                className="tab-content custom_tab_content"
                id="pills-tabContent"
              >
                <div
                  className="tab-pane fade show active custom_tab_pane"
                  id="pills-upcoming-class"
                  role="tabpanel"
                  aria-labelledby="pills-upcoming-class-tab"
                >
                  <div className="sec_1_inner_wrapper">
                    <div
                      className="exercise_collection_wrapper"
                      style={{
                        height: "43vh",
                        overflowY: "auto",
                        padding: "5px",
                      }}
                    >
                      {!isEmpty(state.exercisesData) ? (
                        state.exercisesData.ExerciseList.length ? (
                          state.exercisesData.ExerciseList.filter(
                            (ex) => ex.is_attempt === false
                          ).length ? (
                            state.exercisesData.ExerciseList.filter(
                              (ex) => ex.is_attempt === false
                            ).map((element, index) => {
                              return (
                                <div
                                  key={`non-attempted-${index}`}
                                  className="single_exercise_wrapper"
                                  onClick={() =>
                                    handleStoreDataInStorage(
                                      "exercise",
                                      element
                                    )
                                  }
                                >
                                  <div className="exercise_description_wrapper">
                                    <div className="exercise_details_wrapper">
                                      <div className="text_content_wrapper_1">
                                        <p className="text_content_1">
                                          {element.excersie_type}
                                        </p>
                                      </div>
                                      <div className="text_content_wrapper_2">
                                        <p className="text_content_2">{`${element.total_question} Questions`}</p>
                                      </div>
                                    </div>
                                    <div className="caret_wraper">
                                      <div className="text_content_wrapper">
                                        <span>Start Practice</span>
                                        <img
                                          src={images.new_m2_rgtArow}
                                          alt="arrow"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div
                              className="no_data_found_wrapper"
                              style={{ textAlign: "center", margin: "auto" }}
                            >
                              <img
                                src={images.new_m2_video_ntF}
                                alt="new-ntf-image"
                              />
                              <p>You don't have any exercise for Practice</p>
                            </div>
                          )
                        ) : (
                          <div
                            className="no_data_found_wrapper"
                            style={{ textAlign: "center", margin: "auto" }}
                          >
                            <img
                              src={images.new_m2_video_ntF}
                              alt="new-ntf-image"
                            />
                            <p>There is no exercise yet!</p>
                          </div>
                        )
                      ) : (
                        <div
                          className="no_data_found_wrapper"
                          style={{ textAlign: "center", margin: "auto" }}
                        >
                          <img
                            src={images.new_m2_video_ntF}
                            alt="new-ntf-image"
                          />
                          <p>There is no exercise yet!</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade custom_tab_pane"
                  id="pills-previous-class"
                  role="tabpanel"
                  aria-labelledby="pills-previous-class-tab"
                >
                  <div className="sec_1_inner_wrapper">
                    <div
                      className="exercise_collection_wrapper"
                      style={{
                        height: "43vh",
                        overflowY: "auto",
                        padding: "5px",
                      }}
                    >
                      {!isEmpty(state.exercisesData) ? (
                        state.exercisesData.ExerciseList.length ? (
                          // Filter only exercises where is_attempt is true
                          state.exercisesData.ExerciseList.filter(
                            (ex) => ex.is_attempt === true
                          ).length ? (
                            state.exercisesData.ExerciseList.filter(
                              (ex) => ex.is_attempt === true
                            ).map((element, index) => {
                              return (
                                <div
                                  key={index}
                                  className="single_exercise_wrapper"
                                  onClick={() =>
                                    handleStoreDataInStorage(
                                      "exercise",
                                      element
                                    )
                                  }
                                >
                                  <div className="exercise_description_wrapper">
                                    <div className="exercise_details_wrapper">
                                      <div className="text_content_wrapper_1">
                                        <p className="text_content_1">
                                          {element.excersie_type}
                                        </p>
                                      </div>
                                      <div className="text_content_wrapper_2">
                                        <p className="text_content_2">{`${element.total_question} Questions`}</p>
                                      </div>
                                    </div>
                                    <div className="caret_wraper">
                                      <div className="text_content_wrapper">
                                        <span>Resume Practice</span>
                                        <img
                                          src={images.new_m2_rgtArow}
                                          alt="arrow"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div
                              className="no_data_found_wrapper"
                              style={{ textAlign: "center", margin: "auto" }}
                            >
                              <img
                                src={images.new_m2_video_ntF}
                                alt="new-ntf-image"
                              />
                              <p>You don't have any exercise to Resume</p>
                            </div>
                          )
                        ) : (
                          <div
                            className="no_data_found_wrapper"
                            style={{ textAlign: "center", margin: "auto" }}
                          >
                            <img
                              src={images.new_m2_video_ntF}
                              alt="new-ntf-image"
                            />
                            <p>There is no exercise yet!</p>
                          </div>
                        )
                      ) : (
                        <div
                          className="no_data_found_wrapper"
                          style={{ textAlign: "center", margin: "auto" }}
                        >
                          <img
                            src={images.new_m2_video_ntF}
                            alt="new-ntf-image"
                          />
                          <p>There is no exercise yet!</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderNotesCollectionView = () => {
    return (
      <Fragment>
        <div className="notesCollectionView_wrapper">
          <div
            className="notesCollectionView_inner_wrapper"
            style={{ height: "56vh", overflow: "auto", padding: "8px" }}
          >
            <div className="notes_collection_wrapper">
              {!isEmpty(state.notesData) ? (
                state.notesData.NotesList.data.length ? (
                  state.notesData.NotesList.data.map((element, index) => {
                    return (
                      <div
                        key={index}
                        className="single_notes_wrapper"
                        onClick={() =>
                          handleStoreDataInStorage("notes", element)
                        }
                      >
                        <div className="notes_description_wrapper">
                          <div className="notes_details_wrapper">
                            <div className="text_content_wrapper_1">
                              <p className="text_content_1">
                                {element.notes_title}
                              </p>
                            </div>
                          </div>
                          <div className="arrow">
                            <img src={images.new_m2_rgtArow} alt="" />
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="no_data_found_wrapper"
                    style={{ textAlign: "center", margin: "auto" }}
                  >
                    <img src={images.new_m2_video_ntF} alt="new-ntf-image" />
                    <p>There is no notes yet!</p>
                  </div>
                )
              ) : (
                <div
                  className="no_data_found_wrapper"
                  style={{ textAlign: "center", margin: "auto" }}
                >
                  <img src={images.new_m2_video_ntF} alt="new-ntf-image" />
                  <p>There is no notes yet!</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderDppCollectionView = () => {
    return (
      <Fragment>
        <div
          className="dppCollectionView_wrapper"
          style={{ height: "56vh", overflow: "auto", padding: "8px" }}
        >
          <div className="dppCollectionView_inner_wrapper">
            <div className="sec_1_wrapper">
              <div className="dpp_collection_wrapper">
                {!isEmpty(state.dppCollections) ? (
                  state?.dppCollections?.DPPList?.length ? (
                    state.dppCollections.DPPList.map((element, index) => {
                      return (
                        <div
                          key={index}
                          className="single_dpp_wrapper"
                          onClick={() =>
                            handleStoreDataInStorage("dpp", element)
                          }
                        >
                          <div className="dpp_description_wrapper">
                            <div className="dpp_details_wrapper">
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  {element.excersie_type}
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  {`${element.total_question} Questions`}
                                </p>
                              </div>
                            </div>
                            <div className="caret_wraper">
                              <div className="text_content_wrapper">
                                <span>Start Practice</span>
                                <img src={images.new_m2_rgtArow} alt="arrow" />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      className="no_data_found_wrapper"
                      style={{ textAlign: "center", margin: "auto" }}
                    >
                      <img src={images.new_m2_video_ntF} alt="new-ntf-image" />
                      <p>You don't have any Dpp for Practice</p>
                    </div>
                  )
                ) : (
                  <div
                    className="no_data_found_wrapper"
                    style={{ textAlign: "center", margin: "auto" }}
                  >
                    <img src={images.new_m2_video_ntF} alt="new-ntf-image" />
                    <p>You don't have any Dpp for Practice</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const handleChangeFeature = (event) => {
    let { value } = event.target;

    switch (value) {
      case "videos":
        setState((prevState) => ({
          ...prevState,
          featureType: 1,
          pageNumber: 1,
        }));
        getVideosData();
        break;
      case "live_class":
        setState((prevState) => ({
          ...prevState,
          featureType: 2,
          pageNumber: 1,
        }));
        getRecordedLiveClassData();
        break;
      case "exercise":
        setState((prevState) => ({
          ...prevState,
          featureType: 3,
          pageNumber: 1,
        }));
        getExerciseList();
        break;
      case "notes":
        setState((prevState) => ({
          ...prevState,
          featureType: 4,
          pageNumber: 1,
        }));
        getNotesData();
        break;

      case "dpp":
        setState((prevState) => ({
          ...prevState,
          featureType: 5,
          pageNumber: 1,
        }));
        getDppList();
        break;

      case "additional_videos":
        setState((prevState) => ({
          ...prevState,
          featureType: 6,
          pageNumber: 1,
        }));
        getAdditionalVideosData();
        break;
      default:
        break;
    }
  };

  const getAdditionalVideosData = async () => {
    let data = {
      search: "",
      type: 2,
      course_id: location.state ? crsID : courseDetails[0].course_id,
      subject_id: subjectData?.subjectId,
      topic_id: topicData.topicId,
      faculty_id: state?.additionalCurrentFacultyName?.user_id,
    };
    startLoading();
    try {
      const response = await axios.get(
        `${url_11}?page=1&search=${data.search}&course_id=${data.course_id}&subject_id=${data.subject_id}&topic_id=${data.topic_id}&faculty_id=${data.faculty_id}&type=${data.type}`
      );
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          additionalVideosData: [...response.data.data.VideoList.data],
        }));
      }
    } catch (error) {
      // finishLoading();
      console.log(error);
    }
  };

  let page_limit = 10;

  const fetchVideosData = async () => {
    let pageNo = Math.ceil(state.videosData.length / page_limit) + 1;
    let data = {
      search: "",
      course_id: location.state ? crsID : courseDetails[0].course_id,
      subject_id: subjectData?.subjectId,
      topic_id: topicData.topicId,
      faculty_id: state?.currentFacultyName?.user_id,
      page: pageNo,
    };
    startLoading();
    try {
      const response = await axios.get(
        `${url_11}?page=1&search=${data.search}&course_id=${data.course_id}&subject_id=${data.subject_id}&topic_id=${data.topic_id}&faculty_id=${data.faculty_id}&page=${data.page}`
      );
      if (
        response.data.status === 200 ||
        response.data.data.VideoList.last_page
      ) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          videosData: [
            ...state.videosData,
            ...response.data.data.VideoList.data,
          ],
        }));
      }
    } catch (error) {
      // finishLoading();
      console.log(error);
    }
  };

  const fetchData = () => {
    fetchVideosData();
  };

  const handleRedirect = () => {
    history.push({
      pathname: "/subject_component",
      courseId: location.state,
    });
  };

  return (
    <Fragment>
      <div className="topicContentComponent_wrapper">
        <div className="container">
          <div className="topicContentComponent_inner_wrapper">
            <div className="sec_1_wrapper">
              <div className="sec_1_inner_wrapper">
                <div className="btn_wrapper">
                  <button onClick={() => handleRedirect()}>
                    <img
                      src={images.user_activity_back}
                      alt="user-activity-image"
                    />
                  </button>
                </div>
                <div className="text_content_wrapper">
                  <p className="text_content" style={{ marginTop: "0.1px" }}>
                    {topicData.topicName}
                  </p>
                </div>
              </div>
            </div>
            <div className="sec_2_wrapper">
              <div className="sec_2_inner_wrapper">
                <div className="content_wrapper">
                  <div className="left_side_content_wrapper">
                    <div className="feature_collection_wrapper">
                      {topicData.is_video_available ? (
                        <div className="single_select_feature">
                          <input
                            type="radio"
                            id="videos"
                            name="feature"
                            value="videos"
                            onChange={(event) => handleChangeFeature(event)}
                            checked={state.featureType === 1}
                          />
                          <label htmlFor="videos">Video Lectures</label>
                        </div>
                      ) : null}

                      {topicData.is_live_class_available ? (
                        <div className="single_select_feature">
                          <input
                            type="radio"
                            id="live_class"
                            name="feature"
                            value="live_class"
                            onChange={(event) => handleChangeFeature(event)}
                            checked={state.featureType === 2}
                          />
                          <label htmlFor="live_class">Live class</label>
                        </div>
                      ) : null}
                      {topicData.is_excersice_available ? (
                        <div className="single_select_feature">
                          <input
                            type="radio"
                            id="exercise"
                            name="feature"
                            value="exercise"
                            onChange={(event) => handleChangeFeature(event)}
                            checked={state.featureType === 3}
                          />
                          <label htmlFor="exercise">Exercise</label>
                        </div>
                      ) : null}

                      {topicData.is_dpp_available ? (
                        <div className="single_select_feature">
                          <input
                            type="radio"
                            id="dpp"
                            name="feature"
                            value="dpp"
                            onChange={(event) => handleChangeFeature(event)}
                            checked={state.featureType === 5}
                          />
                          <label htmlFor="dpp">DPP</label>
                        </div>
                      ) : null}

                      {topicData.is_notes_available ? (
                        <div className="single_select_feature">
                          <input
                            type="radio"
                            id="notes"
                            name="feature"
                            value="notes"
                            onChange={(event) => handleChangeFeature(event)}
                            checked={state.featureType === 4}
                          />
                          <label htmlFor="notes">Notes</label>
                        </div>
                      ) : null}

                      {topicData.is_additional_video_available ? (
                        <div className="single_select_feature">
                          <input
                            type="radio"
                            id="additional_videos"
                            name="feature"
                            value="additional_videos"
                            onChange={(event) => handleChangeFeature(event)}
                            checked={state.featureType === 6}
                          />
                          <label htmlFor="additional_videos">
                            Additional Videos
                          </label>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="right_side_content_wrappers">
                    {renderFetaures()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  console.log("state", state);
  return {
    userPreference: state.userPreference,
    subjectData: state.userActivity.subjectData,
    topicData: state.userActivity.topicData,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setFeature: (payload) => {
      dispatch(userActivityActions.setFeature(payload));
    },
    setVideoData: (payload) => {
      dispatch(userActivityActions.setVideoData(payload));
    },
    setRecordedVideoData: (payload) => {
      dispatch(userActivityActions.setRecordedVideoData(payload));
    },
    setNotesData: (payload) => {
      dispatch(userActivityActions.setNotesData(payload));
    },
    setLiveStreamData: (payload) => {
      dispatch(userActivityActions.setLiveStreamData(payload));
    },
    setExerciseData: (payload) => {
      dispatch(userActivityActions.setExerciseData(payload));
    },
    setLiveStreamVideoData: (payload) => {
      dispatch(userActivityActions.setLiveStreamVideoData(payload));
    },

    setLiveVideoStreamDetailsAction: (payload) => {
      dispatch(setLiveVideoStreamDetails(payload));
    },

    setWatchedVideoDetailsAction: (payload) => {
      dispatch(setWatchedVideoDetails(payload));
    },

    setNoteAddedVideoTimeAction: (payload) => {
      dispatch(setNoteAddedVideoTime(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopicContentComponent);
