import { Fragment, useEffect, useState } from "react";
import "./subject_collection_view.scss";
import images from "../../../../../../utilities/images/images";
import axios from "axios";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import { backGroundColorFromBackend } from "../../../../../../custom_utilities/custom_useful_functions";
import { url_9 } from "../../../../../../custom_utilities/api_services";
import Slider from "react-slick";
import { connect } from "react-redux";
import userActivityActions from "../../../../../../redux/user_activity/action";
import { useLocation } from "react-router-dom";

const SubjectCollectionView = (props) => {
  let {
    history,
    userPreference,
    setSubjectData,
    categoryData,
    iscategorizedsubdata,
  } = props;
  let { courseDetails, classDetails } = userPreference;

  const [state, setState] = useState({
    subjectList: {},
  });

  useEffect(() => {
    getSubjectList();
  }, []);

  const getSubjectList = async () => {
    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
    };
    try {
      const response = await axios.post(url_9, data);
      if (response.data.status === 200) {
        props.dispatch(
          userActivityActions.setIsCategorizedSub(response.data.data)
        );

        props.dispatch(
          userActivityActions.setCategoryData({
            category: response.data.category,
            is_categorized_sub: response.data.is_categorized_sub,
          })
        );
        setState((prevState) => ({
          ...prevState,
          subjectList: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderSubjectImage = (icon) => {
    if (icon) {
      return <img src={icon} alt="" />;
    } else {
      return <img src={images.user_dashboard_5} alt="" />;
    }
  };

  const handleRedirect = (type, element) => {
    switch (type.toLowerCase()) {
      case "all":
        history.push("/subject_component");
        break;
      case "subject":
        // Update the selected subject data
        const subject = element.subject?.[0];
        setSubjectData({
          categoryId: element.id, // Pass the categoryId here
          subjectId: subject?.sub_id || "",
          subjectName: subject?.sub_name || "",
          subjectIcon: subject?.icon || "",
          subjects: element.subject || [],
        });

        // Navigate to the SubjectContentComponent and pass categoryId and subjectId via state
        history.push({
          pathname: "/subject_component",
          state: { subjectId: element?.sub_id }, // Passing subjectId and categoryId here
        });
        break;
      default:
        break;
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Fragment>
      <div className="subjectCollectionView_wrapper">
        <div className="subjectCollectionView_inner_wrapper">
          <div className="container">
            <div className="subject_collection">
              <div className="subject_collection_desktop">
                <Slider {...settings}>
                  {iscategorizedsubdata?.is_categorized_sub === 1
                    ? categoryData?.map((element, index) => (
                        <div
                          className="home_page_single_subject_box"
                          key={element?.sub_id}
                        >
                          <div
                            className="home_page_single_subject"
                            onClick={() => handleRedirect("subject", element)}
                            style={backGroundColorFromBackend(
                              element.color_code
                            )}
                          >
                            <div className="home_page_image_wrapper">
                              {renderSubjectImage(element?.icon)}
                            </div>
                            <div className="home_page_text_content_wrapper">
                              <p className="home_page_text_content">
                                {element?.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                    : !isEmpty(state.subjectList) &&
                      state?.subjectList?.subject?.length > 0
                    ? state?.subjectList?.subject.map((subject, index) => (
                        <div
                          className="home_page_single_subject_box"
                          key={subject?.sub_id}
                        >
                          <div
                            className="home_page_single_subject"
                            onClick={() => handleRedirect("subject", subject)}
                            style={backGroundColorFromBackend(
                              subject.color_code
                            )}
                          >
                            <div className="home_page_image_wrapper">
                              {renderSubjectImage(subject?.icon)}
                            </div>
                            <div className="home_page_text_content_wrapper">
                              <p className="home_page_text_content">
                                {subject?.sub_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                    : categoryData?.map((category, index) =>
                        category?.subject?.map((subject) => (
                          <div
                            className="home_page_single_subject_box"
                            key={subject?.sub_id}
                          >
                            <div
                              className="home_page_single_subject"
                              onClick={() => handleRedirect("subject", subject)} // Trigger the update and fetching
                              style={backGroundColorFromBackend(
                                subject.color_code
                              )}
                            >
                              <div className="home_page_image_wrapper">
                                {renderSubjectImage(subject?.icon)}
                              </div>
                              <div className="home_page_text_content_wrapper">
                                <p className="home_page_text_content">
                                  {subject?.sub_name}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                </Slider>
              </div>
              <div className="subject_collection_mobile">
                {!isEmpty(state.subjectList)
                  ? state?.subjectList?.subject?.length
                    ? state?.subjectList?.subject.map((element, index) => {
                        return (
                          <div
                            className="home_page_single_subject_box"
                            key={element?.sub_id}
                          >
                            <div
                              key={index}
                              className="home_page_single_subject"
                              onClick={() => handleRedirect("subject", element)}
                              style={backGroundColorFromBackend(
                                element.color_code
                              )}
                            >
                              <div className="home_page_image_wrapper">
                                {renderSubjectImage(element?.icon)}
                              </div>
                              <div className="home_page_text_content_wrapper">
                                <p className="home_page_text_content">
                                  {element?.sub_name}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : categoryData?.map((category, index) =>
                        category.subject?.map((subject) => (
                          <div
                            className="home_page_single_subject_box"
                            key={subject.sub_id}
                          >
                            <div
                              className="home_page_single_subject"
                              onClick={() => handleRedirect("subject", subject)}
                            >
                              <div className="home_page_image_wrapper">
                                {renderSubjectImage(subject.icon)}
                              </div>
                              <div className="home_page_text_content_wrapper">
                                <p className="home_page_text_content">
                                  {subject.sub_name}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                      )
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
    categoryData: state.userActivity.categoryData,
    iscategorizedsubdata: state.userActivity.is_categorized_sub,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    setSubjectData: (payload) => {
      dispatch(userActivityActions.setSubjectData(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDipatchToProps
)(SubjectCollectionView);
