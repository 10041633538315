import { React, useState } from "react";
import PyqQuestionPaperSolutionContent from "./PyqQuestionPaperSolutionContent";
import {
  url_80,
  url_142,
} from "../../../../../../custom_utilities/api_services";
import axios from "axios";
import { useEffect } from "react";
import LoaderPopUp from "../../../../../../components/loader/loader";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";

const PyqQuestionPaperSolution = (props) => {
  let { startLoading, finishLoading, loader, userData, topicwisepaperID } =
    props;
  const [state, setState] = useState({
    testQuestionsDetails: [],
    currentQuestion: 0,
    currentSolutionDetails: {},
  });
  useEffect(() => {
    getQuestionList();
  }, []);

  const getQuestionList = async () => {
    startLoading();
    let data = {
      paper_id: topicwisepaperID?.find((e) => e.paper_id),
    };

    try {
      const response = await axios.post(url_80, {
        paper_id: data.paper_id.paper_id,
      });

      if (response.data.status === 200) {
        getCurrentSolutionDetails(
          response.data.data[state.currentQuestion].paper_id,
          response.data.data[state.currentQuestion].qid
        );

        setState((preState) => ({
          ...preState,
          testQuestionsDetails: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    } finally {
      finishLoading();
    }
  };

  const getCurrentSolutionDetails = async (paper_id, qid) => {
    const data = {
      paper_id,
      qid,
    };

    startLoading();

    try {
      const response = await axios.post(url_142, data);

      if (response.data.status == 200) {
        finishLoading();

        setState((prevState) => ({
          ...prevState,
          currentSolutionDetails: response.data.data[0],
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    } finally {
      finishLoading();
    }
  };

  //nextBtn
  const handleNextQuestionBtn = (currentQuestion2) => {
    let { currentQuestion, testQuestionsDetails } = state;

    const { paper_id, qid } = testQuestionsDetails[currentQuestion2];

    getCurrentSolutionDetails(paper_id, qid);
    setState((prevState) => ({
      ...prevState,
      currentQuestion: currentQuestion + 1,
      testQuestionsDetails: testQuestionsDetails,
    }));
    window.scrollTo(0, 0);
  };
  //previous btn
  const handlePreviousQuestionBtn = (currentQuestion2) => {
    let { currentQuestion, testQuestionsDetails } = state;

    const { paper_id, qid } = testQuestionsDetails[currentQuestion2];

    getCurrentSolutionDetails(paper_id, qid);
    if (currentQuestion >= 1) {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
        testQuestionsDetails: testQuestionsDetails,
      }));

      window.scrollTo(0, 0);
    }
  };
  //handleJump
  const handleQuestionJump = (questionNumber) => {
    const { paper_id, qid } = state.testQuestionsDetails[questionNumber];

    getCurrentSolutionDetails(paper_id, qid);

    setState((prevState) => ({
      ...prevState,
      currentQuestion: questionNumber,
    }));
  };
  return (
    <div>
      <PyqQuestionPaperSolutionContent
        testQuestionsDetails={state.testQuestionsDetails}
        currentQuestion={state.currentQuestion}
        currentSolutionDetails={state.currentSolutionDetails}
        handlePreviousQuestionBtn={handlePreviousQuestionBtn}
        handleNextQuestionBtn={handleNextQuestionBtn}
        handleQuestionJump={handleQuestionJump}
        userId={userData.userId}
      />
      {loader && <LoaderPopUp />}
    </div>
  );
};
const mapStateToProps = (state) => {
  console.log("rounak", state);
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    topicwisepaperID:
      state.PyqDailyChallengeReducer.questionsList.data.AllQuestionList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PyqQuestionPaperSolution);
