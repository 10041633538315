import React, { useEffect, useState } from "react";
import axios from "axios";
import { START_LOADING } from "../../../../redux/ui/action_type";
import "./OverallStrongAndImproveTopics.scss";
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";
import { url_141 } from "../../../../custom_utilities/api_services";

function OverallStrongAndImproveTopics(props) {
  const { startLoading, finishLoading, subjects } = props;
  const [ImproveTopicList, setImproveTopicList] = useState([]);
  const [StrongTopicList, setStrongTopicList] = useState([]);

  const [subject, setSubject] = useState("Physics");
  const [strongSubject, setStrongSubject] = useState("Physics");

  useEffect(() => {
    getImproveTopicList(subject, "Improve");
  }, [subject]);

  useEffect(() => {
    getStrongTopicList(subject, "Storng");
  }, [strongSubject]);

  const getStrongTopicList = async (subject, type) => {
    startLoading();

    try {
      const data = {
        subject,
        type,
      };
      const response = await axios.post(url_141, data);

      if (response.data.status === 200) {
        setStrongTopicList([...response.data.data.StrongTopicList]);
        finishLoading();
      }
    } catch (error) {
      console.log(error.message);
      finishLoading();
    }
  };

  const getImproveTopicList = async (subject, type) => {
    startLoading();
    try {
      const data = {
        subject,
        type,
      };
      const response = await axios.post(url_141, data);
      if (response.data.status === 200) {
        finishLoading();

        setImproveTopicList([...response.data.data.ImproveTopicList]);
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  return (
    <>
      <div className="sec_1_inner_wrapper">
        <div className="strong_topic_dropdown_box">
          <div className="strong_topic_dropdown">
            <div className="heading-text">Strong Topic</div>

            <div>
              <select
                onChange={(event) => {
                  setStrongSubject(event.target.value);
                }}
              >
                {subjects?.map((element) => {
                  return (
                    <option value={element.Subject}>{element.Subject}</option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        {/* <hr></hr> */}

        <div className="strong_topic_lists">
          <ol>
            {StrongTopicList.length > 0 &&
              StrongTopicList.map((element) => {
                // return <li>{element.topic}</li>;
                return (
                  <div className="strong_topic_list">
                    <li>{element.topic}</li>
                    {/* <span className="right-icon">s</span> */}
                  </div>
                );
              })}
          </ol>
        </div>
      </div>

      <div className="sec_1_inner_wrapper">
        <div className="improve_topic_dropdown_box">
          <div className="improve_topic_dropdown">
            <div className="heading-text">Areas to Improve</div>

            <div>
              <select
                onChange={(event) => {
                  setSubject(event.target.value);
                }}
              >
                {subjects?.map((element) => {
                  return (
                    <option value={element.Subject}>{element.Subject}</option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        {/* <hr></hr> */}

        <div className="improve_topic_lists">
          <ol>
            {ImproveTopicList.length > 0 &&
              ImproveTopicList.map((element) => {
                return (
                  <div className="improve_topic_list">
                    <li>{element.topic}</li>
                    {/* <span className="start_practice">Start Practice</span> */}
                  </div>
                );
              })}
          </ol>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(null, mapDispatchToProps)(OverallStrongAndImproveTopics);
