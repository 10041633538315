import * as actionTypes from "./action_type";

// Define action creators
const setSubjectData = (payload) => {
  return { type: actionTypes.SET_SUBJECT_DATA, payload };
};

const setTopicData = (payload) => {
  return {
    type: actionTypes.SET_TOPIC_DATA,
    payload,
  };
};

const setCourseIdData = (payload) => ({
  type: actionTypes.SET_COURSEID_DATA,
  payload,
});

const setPyqTopicData = (payload) => ({
  type: actionTypes.SET_PYQTOPIC_DATA,
  payload,
});

const setPyqTopicSubject = (payload) => ({
  type: actionTypes.SET_PYQTOPIC_TOPIC_SUBJECT,
  payload,
});

const setExamCrackerTopicData = (payload) => ({
  type: actionTypes.SET_EXAM_CRACKER_TOPIC_DATA,
  payload,
});

const setFeature = (payload) => ({
  type: actionTypes.SET_FEATURE,
  payload,
});

const setVideoData = (payload) => ({
  type: actionTypes.SET_VIDEO_DATA,
  payload,
});

const setRecordedVideoData = (payload) => ({
  type: actionTypes.SET_RECORDED_VIDEO_DATA,
  payload,
});

const setNotesData = (payload) => ({
  type: actionTypes.SET_NOTES_DATA,
  payload,
});

const setLiveStreamData = (payload) => ({
  type: actionTypes.SET_LIVE_STREAM_DATA,
  payload,
});

const setExerciseData = (payload) => ({
  type: actionTypes.SET_EXERCISE_DATA,
  payload,
});

const setExerciseResultData = (payload) => ({
  type: actionTypes.SET_EXERCISE_RESULT_DATA,
  payload,
});

const setLiveStreamVideoData = (payload) => ({
  type: actionTypes.SET_LIVE_STREAM_VIDEO_DATA,
  payload,
});

const removeActivityData = () => ({
  type: actionTypes.REMOVE_ACTIVITY_DATA,
});

const setCommonBannerData = (payload) => ({
  type: actionTypes.SET_COMMON_BANNER_DATA,
  payload,
});

const setCategoryData = (payload) => {
  return {
    type: actionTypes.SET_CATEGORY_DATA,
    payload,
  };
};

const setIsCategorizedSub = (payload) => {
  return {
    type: actionTypes.SET_IS_CATEGORIZED_SUB,
    payload,
  };
};

// Aggregate action creators in an object
const actions = {
  setSubjectData,
  setTopicData,
  setCourseIdData,
  setPyqTopicData,
  setExamCrackerTopicData,
  setFeature,
  setVideoData,
  setRecordedVideoData,
  setNotesData,
  setLiveStreamData,
  setExerciseData,
  setExerciseResultData,
  setLiveStreamVideoData,
  removeActivityData,
  setCommonBannerData,
  setPyqTopicSubject,
  setCategoryData,
  setIsCategorizedSub,
};

// Export as default
export default actions;
