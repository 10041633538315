import m2_logo from "../images/main_logo/m2-logo.png";
import m2_lvCls from "../images/homepage/m2_lvCls.png";
import m2_tstAnls from "../images/homepage/m2_tstAnls.png";
import m2_ulmtDts from "../images/homepage/m2_ulmtDts.png";
import m2_loginIcon from "../images/homepage/m2_loginIcon.png";
import m2_googlePlay from "../images/homepage/m2_googlePlay.png";
import m2_jee from "../images/homepage/m2_jee.png";
import m2_neet from "../images/homepage/m2_neet.png";
import m2_foundation from "../images/homepage/m2_foundation.png";
import m2_live from "../images/homepage/m2_live.png";
import m2_dpp from "../images/homepage/m2_dpp.png";
import m2_video from "../images/homepage/m2_video.png";
import m2_tests from "../images/homepage/m2_tests.png";
import m2_notes from "../images/homepage/m2_notes.png";
import m2_testSeries from "../images/homepage/m2_testSeries.png";
import m2_upArrow from "../images/homepage/m2_upArrow.png";
import m2_rgtArr from "../images/homepage/m2_rgtArr.png";
import new_m2_rgtArr_blk from "../images/user_dashboard/new_m2_rgtArr_blk.png";
import new_warning_icon from "../images/user_activity/new_warning_icon.png";
import notification from "../images/homepage/m2_notification.png";
import m2_search from "../images/homepage/m2_search.png";
import right_Ar from "../images/homepage/right_Ar.png";
import down_ar from "../images/homepage/down_ar.png";
import locked_symbol from "../images/practice/lock.png";
import close_btn from "../images/practice/Info.svg";
import m2_slider1 from "../images/user_dashboard/m2_slider1.png";
import m2_institute from "../images/user_dashboard/m2_institute.jpg";
import m2_target from "../images/user_dashboard/m2_target.png";
import m2_rgtArow from "../images/user_dashboard/m2_rgtArow.png";
import m2_rgtArow1 from "../images/user_dashboard/m2_rgtArow1.png";
import m2_rgtArow2 from "../images/user_dashboard/m2_rgtArow2.png";
import new_m2_rgtArow from "../images/user_dashboard/new_m2_rgtArow.png";
import m2_bkmark from "../images/user_activity/m2_bkmark.png";
import m2_bkNulTest from "../images/homepage/M2_test.png";
import m2_bkNulExr from "../images/homepage/m2_exercise.png";
import m2_bkNulDp from "../images/homepage/M2_Dppp.png";
import m2_bkVdo from "../images/homepage/m2_bkVdo.png";
import m2_store from "../images/user_dashboard/m2_store.png";
import m2_video_ntF from "../images/homepage/m2_video_ntF.png";
import new_m2_video_ntF from "../images/homepage/new_m2_video_ntF.png";
import new_m2_test_ntF from "../images/homepage/new_m2_test_ntF.png";
import m2_backBtn from "../images/homepage/m2_backBtn.png";
import m2_complete from "../images/homepage/m2_complete.png";
import m2_rank from "../images/homepage/rank.png";
import m2_marks from "../images/homepage/marks.png";
import m2_exit from "../images/homepage/exit.png";
import m2_exit1 from "../images/homepage/exit1.png";
import m2_language from "../images/homepage/language.png";
import m2_seeAll from "../images/homepage/seeAll.png";
import M2Video from "../images/homepage/M2Video.png";
import M2Notification from "../images/homepage/M2notification.png";
import M2Doubts from "../images/homepage/M2Doubts.png";
import M2Doubts2 from "../images/homepage/M2Doubts2.png";
import doubts_no_data from "../images/doubts/doubts_no_data.png";
import plus_icon from "../images/doubts/plus_icon.png";

// import motion_logo from "../images/main_logo/motion_logo.png";
// import motion_logo_new from "../images/main_logo/motion_logo_new.png";

import motion_logo from "../images/main_logo/motion-logo-new.png";
import motion_logo_new from "../images/main_logo/motion-logo-new.png";

import navonmesh_logo from "../images/main_logo/navonmesh.png";
import motion_logo_svg from "../images/main_logo/motion_logo_svg.svg";
import homepage_2 from "../images/homepage/homepage_2.png";
import homepage_3 from "../images/homepage/homepage_3.png";
import homepage_4 from "../images/homepage/homepage_4.png";
import homepage_5 from "../images/homepage/homepage_5.png";
import homepage_6 from "../images/homepage/homepage_6.png";
import homepage_7 from "../images/homepage/homepage_7.png";
import homepage_9 from "../images/homepage/homepage_9.png";
import expert_1 from "../images/homepage/expert_1.png";
import expert_2 from "../images/homepage/expert_2.png";
import expert_3 from "../images/homepage/expert_3.png";
import homepage_10 from "../images/homepage/homepage_10.png";
import homepage_11 from "../images/homepage/homepage_11.png";
import homepage_12 from "../images/homepage/homepage_12.png";
import homepage_13 from "../images/homepage/homepage_13.png";
import homepage_14 from "../images/homepage/homepage_14.png";
import homepage_15 from "../images/homepage/homepage_15.png";
import homepage_16 from "../images/homepage/homepage_16.png";
import homepage_17 from "../images/homepage/homepage_17.png";
import homepage_18 from "../images/homepage/homepage_18.png";
import homepage_20 from "../images/homepage/homepage_20.png";

import login_1 from "../images/login/login_1.png";
import login_2 from "../images/login/login_2.png";
import login_3 from "../images/login/login_3.png";
import signUp_1 from "../images/signUp/signUp_1.png";
import signUp_2 from "../images/signUp/signUp_2.png";
import signUp_3 from "../images/signUp/signUp_3.png";
import signUp_4 from "../images/signUp/signUp_4.png";
///////////////////////////////////////
import active_home from "../images/left_sideBar/active_icons/active_home.png";
import active_exercise from "../images/left_sideBar/active_icons/active_exercise.png";
import active_doubts from "../images/left_sideBar/active_icons/active_doubts.png";
import active_analytics from "../images/left_sideBar/active_icons/active_analytics.png";
import active_store from "../images/left_sideBar/active_icons/active_store.png";
import active_bookmark from "../images/left_sideBar/active_icons/active_bookmark.png";
import active_transaction from "../images/left_sideBar/active_icons/active_transaction.png";
import inactive_home from "../images/left_sideBar/inactive_icons/inactive_home.png";
import inactive_exercise from "../images/left_sideBar/inactive_icons/inactive_exercise.png";
import inactive_doubts from "../images/left_sideBar/inactive_icons/inactive_doubts.png";
import inactive_analytics from "../images/left_sideBar/inactive_icons/inactive_analytics.png";
import inactive_store from "../images/left_sideBar/inactive_icons/inactive_store.png";
import inactive_bookmark from "../images/left_sideBar/inactive_icons/inactive_bookmark.png";
import inactive_transaction from "../images/left_sideBar/inactive_icons/inactive_transaction.png";

/////////////////////////////////
import header_1 from "../images/header/header_1.png";
import header_2 from "../images/header/header_2.png";
import header_3 from "../images/header/header_3.png";
import header_4 from "../images/header/header_4.png";
import header_5 from "../images/header/header_5.png";
import header_6 from "../images/header/header_6.png";
import header_7 from "../images/header/header_7.png";
import header_8 from "../images/header/header_8.png";

import dummy_user_img from "../images/main_logo/dummy_user_img.png";
import user_dashboard_1 from "../images/user_dashboard/user_dashboard_1.png";
import user_dashboard_2 from "../images/user_dashboard/user_dashboard_2.png";
import user_dashboard_3 from "../images/user_dashboard/user_dashboard_3.png";
import user_dashboard_4 from "../images/user_dashboard/user_dashboard_4.png";
import user_dashboard_5 from "../images/user_dashboard/user_dashboard_5.png";
import user_dashboard_6 from "../images/user_dashboard/user_dashboard_6.png";
import user_profile_1 from "../images/user_profile/user_profile_1.png";
import user_profile_3 from "../images/user_profile/user_profile_3.png";
import user_activity_1 from "../images/user_activity/user_activity_1.png";
import user_activity_2 from "../images/user_activity/user_activity_2.png";
import user_activity_3 from "../images/user_activity/user_activity_3.png";
import user_activity_4 from "../images/user_activity/user_activity_4.png";
import user_activity_bookmark_on from "../images/user_activity/user_activity_bookmark_on.png";
import user_activity_bookmark_off from "../images/user_activity/user_activity_bookmark_off.png";
import user_activity_5 from "../images/user_activity/user_activity_5.png";
import no_data from "../images/main_logo/no_data.png";
import user_activity_6 from "../images/user_activity/user_activity_6.png";
import user_activity_7 from "../images/user_activity/user_activity_7.png";
import user_activity_8 from "../images/user_activity/user_activity_8.png";
import user_activity_back from "../images/user_activity/user_activity_back.png";
import user_activity_calendar from "../images/user_activity/user_activity_calendar.png";
import user_dashboard_7 from "../images/user_dashboard/user_dashboard_7.jpeg";
import user_dashboard_8 from "../images/user_dashboard/user_dashboard_8.jpg";
import user_dashboard_9 from "../images/user_dashboard/user_dashboard_9.jpg";
import user_dashboard_11 from "../images/user_dashboard/user_dashboard_11.png";
import coin_welcom from "../images/user_dashboard/coin_welcom.png";
import coins from "../images/user_dashboard/coins.png";
import rocket_logo from "../images/user_dashboard/rocket_logo.png";
import new_coupon from "../images/user_dashboard/new_coupon.png";
import slider_arrow from "../images/user_dashboard/slider_arrow.png";
import store_motion_coins from "../images/user_dashboard/store_motion_coins.png";
import store_box from "../images/user_dashboard/store_box.png";
import thumbs_up from "../images/user_dashboard/thumbs_up.png";
///////
import user_chat_1 from "../images/user_chat/user_chat_1.png";
import user_chat_2 from "../images/user_chat/user_chat_2.png";
import user_chat_3 from "../images/user_chat/user_chat_3.png";
import user_activity_9 from "../images/user_activity/user_activity_9.png";
import user_activity_10 from "../images/user_activity/user_activity_10.png";
import user_activity_11 from "../images/user_activity/user_activity_11.png";
import user_activity_12 from "../images/user_activity/user_activity_12.png";
import evaluation_1 from "../images/evaluation/evaluation_1.png";
import user_activity_13 from "../images/user_activity/user_activity_13.png";
import new_user_activity_13 from "../images/user_activity/new_user_activity_13.png";
import user_activity_grid from "../images/user_activity/grid.svg";
import user_activity_grid1 from "../images/user_activity/grid1.svg";
import user_activity_list from "../images/user_activity/list.svg";
import user_activity_list1 from "../images/user_activity/list1.svg";
// import user_activity_14 from "../images/user_activity/user_activity_14.png";

import evaluation_2 from "../images/evaluation/evaluation_2.png";
import evaluation_3 from "../images/evaluation/evaluation_3.png";
import evaluation_4 from "../images/evaluation/evaluation_4.png";
import user_dashboard_10 from "../images/user_dashboard/user_dashboard_10.png";
import user_activity_14 from "../images/user_activity/user_activity_14.png";
import user_activity_14_1 from "../images/user_activity/user_activity_14_1.svg";

//  online test series images

import user_activity_15 from "../images/user_activity/user_activity_15.png";
import user_activity_16 from "../images/user_activity/user_activity_16.png";
import user_activity_17 from "../images/user_activity/user_activity_17.png";
import user_activity_18 from "../images/user_activity/user_activity_18.png";
import new_user_activity_18 from "../images/user_activity/new_user_activity_18.png";
import user_activity_19 from "../images/user_activity/user_activity_19.png";
import user_activity_20 from "../images/user_activity/user_activity_20.png";
import user_activity_21 from "../images/user_activity/user_activity_21.png";
import user_activity_22 from "../images/user_activity/user_activity_22.png";
import user_activity_23 from "../images/user_activity/user_activity_23.png";
import new_user_activity_23 from "../images/user_activity/new_user_activity_23.png";
import user_activity_56 from "../images/user_activity/user_activity_56.png";
import user_activity_57 from "../images/user_activity/user_activity_57.png";
import new_user_activity_57 from "../images/user_activity/new_user_activity_57.png";
import user_activity_58 from "../images/user_activity/user_activity_58.png";
import new_user_activity_58 from "../images/user_activity/new_user_activity_58.png";
import user_activity_59 from "../images/user_activity/user_activity_59.png";
import user_activity_60 from "../images/user_activity/user_activity_60.png";

//  exam cracker images
import user_activity_24 from "../images/user_activity/user_activity_24.png";
import user_activity_25 from "../images/user_activity/user_activity_25.png";
import user_activity_26 from "../images/user_activity/user_activity_26.png";

//  bit sat images
import user_activity_30 from "../images/user_activity/user_activity_30.png";
import user_activity_31 from "../images/user_activity/user_activity_31.png";
import mock_test_bit_sat_1 from "../images/user_activity/mock_test_bit_sat_1.png";

// import user_activity_calendar from "../images/user_activity/user_activity_calendar.png";

/// store new images

import user_activity_39 from "../images/user_activity/user_activity_39.png";
import user_activity_38 from "../images/user_activity/user_activity_38.png";
import user_activity_40 from "../images/user_activity/user_activity_40.png";
import user_activity_41 from "../images/user_activity/user_activity_41.png";
import user_activity_42 from "../images/user_activity/user_activity_42.png";
import user_activity_43 from "../images/user_activity/user_activity_43.png";
import user_activity_44 from "../images/user_activity/user_activity_44.png";
import store_activiy_1 from "../images/store/store_activity_1.png";
import store_link from "../images/store/store_link.png";
import terms_and_condition from "../images/store/terms_and_condition.png";
import store_tick from "../images/store/store_tick.png";
import new_store_tick from "../images/store/new_store_tick.png";
import new_store_tick_list from "../images/store/new_store_tick_list.png";
import store_offer from "../images/store/store_offer.png";
import new_store_banner from "../images/store/new_store_banner.png";
import info from "../images/store/info.png";
import store_traning from "../images/store/store_traning_icon.png";
import store_learning from "../images/store/store_learning_icon.png";
import store_report from "../images/store/store_report_icon.png";
import store_e_learning from "../images/store/store_e_learning_icon.png";
import store_video_sol from "../images/store/store_video_sol_icon.png";
import store_analytics from "../images/store/store_tabler_report-analytics.png";
import amrit_gurantee from "../images/store/amrit-gurantee.png";

/// refund

import refund_submit from "../images/refund/refund-submit.png";
import refund_img from "../images/refund/refund-img.png";

/// SPR
import user_activity_45 from "../images/user_activity/user_activity_45.png";
import user_activity_46 from "../images/user_activity/user_activity_46.png";
import user_activity_46_1 from "../images/user_activity/user_activity_46_1.svg";
import user_activity_47 from "../images/user_activity/user_activity_47.png";
import user_activity_48 from "../images/user_activity/user_activity_48.png";
import user_activity_48_1 from "../images/user_activity/user_activity_48_1.svg";
import user_activity_49 from "../images/user_activity/user_activity_49.png";
import user_activity_50 from "../images/user_activity/user_activity_50.png";
import user_activity_51 from "../images/user_activity/user_activity_51.png";
import user_activity_52 from "../images/user_activity/user_activity_52.png";
import user_activity_53 from "../images/user_activity/user_activity_53.png";
import user_activity_54 from "../images/user_activity/user_activity_54.png";
import user_activity_54_1 from "../images/user_activity/user_activity_54_1.png";
import logout from "../images/parent-homepage/logout.png";
import user_activity_62 from "../images/user_activity/user_activity_62.png";
import user_activity_63 from "../images/user_activity/user_activity_63.png";
import user_activity_64 from "../images/user_activity/user_activity_64.png";
import user_activity_65 from "../images/user_activity/user_activity_65.png";

//question bank
import user_activity_32 from "../images/user_activity/user_activity_32.png";
import user_activity_33 from "../images/user_activity/user_activity_33.png";
import user_activity_34 from "../images/user_activity/user_activity_34.png";
import user_activity_35 from "../images/user_activity/user_activity_35.png";
import user_activity_36 from "../images/user_activity/user_activity_36.png";
import new_user_activity_36 from "../images/user_activity/new_user_activity_36.png";
import user_activity_37 from "../images/user_activity/user_activity_37.png";
import new_user_activity_37 from "../images/user_activity/new_user_activity_37.png";
import user_activity_55 from "../images/user_activity/user_activity_55.png";

// Adaptive CPS
import acps_banner from "../images/adaptive_cps/acps_banner.png";
import acps_banner_plus from "../images/adaptive_cps/acps_banner_plus.png";

// import user_activity_38 from "../images/user_activity/user_activity_38.png";
import user_activity_61 from "../images/user_activity/user_activity_61.png";

//Faculty images
//Physics
import physics_expert_1 from "../images/faculty/physics/Nitin-Vijay-200x200.jpg";
import physics_expert_2 from "../images/faculty/physics/Amit-Verma-200x200.jpg";
import physics_expert_3 from "../images/faculty/physics/Avinash-Kishore.jpeg";
import physics_expert_4 from "../images/faculty/physics/Amit-Kumar-Rathore-200x200.jpg";
import physics_expert_5 from "../images/faculty/physics/Krantideep-Singh.png";
import physics_expert_6 from "../images/faculty/physics/Pavan-Vijay-1-200x200.jpg";
import physics_expert_7 from "../images/faculty/physics/Durgesh-Pandey-200x200.jpg";
//Chemistry
import chemistry_expert_1 from "../images/faculty/chemistry/Ram-Ratan-Dwivedi-200x200.jpg";
import chemistry_expert_2 from "../images/faculty/chemistry/Vijay-Pratap-Singh-200x200.jpg";
import chemistry_expert_3 from "../images/faculty/chemistry/Anurag-Garg-200x200.jpg";
import chemistry_expert_4 from "../images/faculty/chemistry/Pravesh-Garg-200x200.jpg";
import chemistry_expert_5 from "../images/faculty/chemistry/Shantanu-Gupta-200x200.jpg";
import chemistry_expert_6 from "../images/faculty/chemistry/Devki-Nandan-Pathak-200x200.jpg";
import chemistry_expert_7 from "../images/faculty/chemistry/Harmeet-Singh-Bindra-200x200.jpg";
import chemistry_expert_8 from "../images/faculty/chemistry/Bharat-Bhushan-Sonakiya-200x200.jpg";
import chemistry_expert_9 from "../images/faculty/chemistry/Sanjeev-Kumar-Bhadoriya-200x200.jpg";
//Mathematics
import mathemetics_expert_1 from "../images/faculty/maths/Nikhil-Srivastav-200x200.jpg";
import mathemetics_expert_2 from "../images/faculty/maths/Arjun-Gupta-1-200x200.jpg";
import mathemetics_expert_3 from "../images/faculty/maths/Aatish-Agarwal-1-200x200.jpg";
import mathemetics_expert_4 from "../images/faculty/maths/Jayant-Chittora-200x200.jpg";
import mathemetics_expert_5 from "../images/faculty/maths/Vipin-Sharma-1-200x200.jpg";
//Biology
import biology_expert_1 from "../images/faculty/biology/Renu-Singh-200x200.jpg";
import biology_expert_2 from "../images/faculty/biology/Harshit-Thakuria.png";
// Wallet
import backBtn from "../images/wallet/backBtn.png";
import backBtn1 from "../images/wallet/backBtn1.png";

import motionCoin from "../images/wallet/MotionCoins.png";
import new_motionCoin from "../images/wallet/new_motionCoin.png";
import new_motionCoin1 from "../images/wallet/new_motionCoin1.png";
import motionWalletNew from "../images/wallet/wallet_banner.png";
import motionWallet from "../images/wallet/MotionCoinswallet.png";
import transactions from "../images/wallet/transactions.png";
import new_transactions from "../images/wallet/new_transactions.png";
import motionCoinsStore from "../images/wallet/MotionCoinsStore.png";

//help and support

import helpAndSupport from "../images/user_activity/help-support-img.png";
import moitonchatLogo from "../images/user_activity/motion-chat-logo.png";
import chatSendIcon from "../images/user_activity/chatsend-icon.png";
import new_chatSendIcon from "../images/user_activity/new_chatSendIcon.png";

import uploadFile from "../images/user_activity/upload-file.png";

//popups

import logOut from "../images/popups/logout.png";
import prohibition from "../images/popups/prohibition.png";
import warning from "../images/popups/warning.png";
import profile from "../images/popups/profile.png";
import MutipleLogin from "../images/popups/multiple-login.png";

//common banner for examcracker, CPS and ACPS
import commonBannerLogo from "../images/common_banner/common-banner-logo.png";
import commonBannerCircle from "../images/common_banner/common_banner-ellipse.png";
import commonBannerLogoCps from "../images/common_banner/common-banner-logo-cps.png";

// new live streaming

import chatIcon from "../images/new_live_streaming/chat-icon.png";
import sendBtn from "../images/new_live_streaming/send-btn.png";
import emojiIcon from "../images/new_live_streaming/emoji-icon.png";
import eyeView from "../images/new_live_streaming/eyeview.png";
import greaterThan from "../images/new_live_streaming/greather-than.png";
import ratingIcon from "../images/new_live_streaming/rating-icon.png";
import nvSirProPic from "../images/new_live_streaming/nvsirpropic.png";
import motionLogo from "../images/new_live_streaming/motion-logo.png";
import pollLogo from "../images/new_live_streaming/poll.png";
import pollIcon from "../images/new_live_streaming/poll-icon.png";

import BookmarkBtn from "../images/test/bookmark.svg";
import BookmarkBtn1 from "../images/test/bookmark1.svg";

import multi from "../images/multi.png";
import new_pdf from "../images/new_pdf.png";
import new_download from "../images/new_download.png";
import new_empty_star from "../images/new_empty_star.png";
import new_full_star from "../images/new_full_star.png";
import new_small_empty_star from "../images/new_empty_star.png";
import new_small_full_star from "../images/new_full_star.png";
import new_send from "../images/new_send.png";
import new_likes from "../images/new_likes.png";
import new_liked from "../images/new_liked.png";
import new_report from "../images/new_report.png";
import new_folder_warning from "../images/new_folder_warning.png";
import new_options from "../images/new_options.png";
import edit_note from "../images/edit_note.png";
import delete_note from "../images/delete_note.png";

//bookmarks

import bookmark from "../images/bookmarks/bookmark.png";
import rating from "../images/bookmarks/rating-start.png";
import noBookmark from "../images/bookmarks/no-bookmark.png";

import exploreCourse from "../images/user_dashboard/exploreCourse.png";
import arrow from "../images/user_dashboard/schedule-class-arrow.png";

//  ---------------------------------NEW LANDING PAGE----------------------------
import newLandingBg from "../images/new_login_landing_page/landing-sec-bg.png";
import landingPageLogo from "../images/new_login_landing_page/motion-logo-landing.png";
import callIcon from "../images/new_login_landing_page/call.png";
import phInfo from "../images/new_login_landing_page/ph_info.jpg";
import storeBanner from "../images/new_login_landing_page/storeBanner.png";
import whatsaap from "../images/new_login_landing_page/whatsapp.png";
import phoneCall from "../images/new_login_landing_page/phone-call.png";

// course
import jee from "../images/new_login_landing_page/courses/jee.png";
import neet from "../images/new_login_landing_page/courses/neet.png";
import foundation from "../images/new_login_landing_page/courses/foundation.png";
import cuet from "../images/new_login_landing_page/courses/cuet.png";

import NvSir from "../images/new_login_landing_page/banner/nvsir.png";
import SpinImg from "../images/new_login_landing_page/banner/SpinImg.png";
import coupon from "../images/new_login_landing_page/coupon.jpg";

// benifits
import benifitsCommonImg from "../images/new_login_landing_page/benifits/benifit-common-img.png";
import doubtsImg from "../images/new_login_landing_page/benifits/doubts-img.png";
import practiceImg from "../images/new_login_landing_page/benifits/practice-img.png";
import reportImg from "../images/new_login_landing_page/benifits/report-img.png";
import right from "../images/new_login_landing_page/benifits/right.png";
import nv_sir from "../images/new_login_landing_page/nv_sir.png";
import learningPerformance from "../images/new_login_landing_page/learning_performance.png";

// packages

import pkg1 from "../images/new_login_landing_page/packages/pkg1.png";
import pkg2 from "../images/new_login_landing_page/packages/pkg2.png";
import pkg3 from "../images/new_login_landing_page/packages/pkg3.png";

// news

import news1 from "../images/new_login_landing_page/news/news1.png";
import news2 from "../images/new_login_landing_page/news/news2.png";
import news3 from "../images/new_login_landing_page/news/news3.png";

// playstore
import playStore from "../images/new_login_landing_page/app-details/playstore.png";

// login page
import loginPageImg from "../images/new_login_landing_page/login/login-page-img.png";
import loginCloseBtn from "../images/new_login_landing_page/login/login-closebtn.png";

// why motion

import whyMotionBanner from "../images/new_login_landing_page/why-motion/why-motion-banner.png";
import studentReviewIcon from "../images/new_login_landing_page/why-motion/student-review-icon.png";
import leftArrowCircle from "../images/new_login_landing_page/why-motion/left-arrow-circle.png";
import rightArrowCircle from "../images/new_login_landing_page/why-motion/right-arrow-circle.png";

// contact us

import contactUsBanner from "../images/new_login_landing_page/contact-us/contact-us-banner.png";
import mobileContactUsBanner from "../images/new_login_landing_page/contact-us/mobile-contact-us-banner.png";
import nextImg from "../images/new_login_landing_page/nextImg.png";
import tick from "./tick.png";
import noReferals from "../images/refer&earn/noreferals.png";

//watch free videos
import watchFreeVideos from "../images/watch-free-videos/watch-free-videos.png";
import watchFreeVideoIcon from "../images/watch-free-videos/video-icon.png";
import watchFreeVideoArrow from "../images/watch-free-videos/watch-video-right-arrow.png";

import cashIcon from "../images/refer&earn/cash-icon.png";
import copyIcon from "../images/refer&earn/copy-icon.png";
import checkBoxTickIcon from "../images/refer&earn/check_box_tick.png";
import exclamationIcon from "../images/refer&earn/exclamationIcon.png";
import newBackBtn from "../images/refer&earn/BackBtn.png";
import successfulIcon from "../images/refer&earn/successfull.png";
import invalidlIcon from "../images/refer&earn/invalid.png";
import whatsappIcon from "../images/refer&earn/watsappIcon.png";
import shareIcon from "../images/refer&earn/shareIcon.png";
import dropDownIcon from "../images/refer&earn/dropdown.png";
import leftArrow from "../images/left_arrow.png";

import coinImg from "../images/store/coinImg.png";
import storeBck from "../images/store/storeBck.png";
import storeRightArrow from "../images/store/storeRightArrow.png";
import studentPic from "../images/parent-homepage/studentpic.png";

//parent
import expectedIcon from "../images/parent-homepage/expected.png";
import allIndiaIcon from "../images/parent-homepage/allindia.png";
import yourMarksIcon from "../images/parent-homepage/yourmarks.png";
import topperMarksIcon from "../images/parent-homepage/toppermarks.png";
import parentNoDataIcon from "../images/parent-homepage/parent-no-data-img.png";
import plusIcon from "../images/parent-homepage/plus-icon.png";
import parentRightIcon from "../images/parent-homepage/parent-right-icon.png";

import bookmark_img from "./bookmark_img.png";
import unattempted from "./unattempted.png";
import hamburg from "./hamburg.png";

// new handbook

import locationIcon from "./store/new_handbook/locationIcon.png";
import handbookCart from "./store/new_handbook/handbookCart.png";
import handbookDelete from "./store/new_handbook/handbookDelete.png";
import handbookEmptyCart from "./store/new_handbook/handbookEmptyCart.png";
import handbookOrderSuccess from "./store/new_handbook/handbookOrderSuccess.png";
import handbookOrderFailed from "./store/new_handbook/handbookOrderFailed.png";

import handbookIncrease from "./store/new_handbook/handbookIncrease.png";
import handbookDecrease from "./store/new_handbook/handbookDecrease.png";
import handbookCartEmptyImg from "./store/new_handbook/handbookCartEmptyImg.png";
import handbookImg from "./store/new_handbook/handbookImg.png";
import homeImg from "./home.png";
import StoreVector from "./storevector.png";
import bookmarkVector from "./bookmarksvector.png";
import questionVector from "./questionBankvector.png";
import doubtsVector from "./doubtsvector.png";
import practiceVector from "./practicevector.png";
import infosvg from "./info.svg";
import infopng from "./infopng.png";
import homepageBanner from "./homepageBanner.png";
const images = {
  new_pdf,
  new_download,
  new_empty_star,
  new_full_star,
  new_send,
  new_small_empty_star,
  new_small_full_star,
  new_likes,
  new_liked,
  new_report,
  new_folder_warning,
  new_options,
  edit_note,
  delete_note,
  multi,
  bookmark,
  noBookmark,
  rating,
  M2Doubts,
  M2Doubts2,
  doubts_no_data,
  plus_icon,
  M2Video,
  M2Notification,
  m2_logo,
  m2_lvCls,
  m2_tstAnls,
  m2_ulmtDts,
  m2_loginIcon,
  m2_googlePlay,
  m2_jee,
  m2_neet,
  m2_foundation,
  m2_live,
  m2_dpp,
  m2_video,
  m2_tests,
  m2_notes,
  m2_testSeries,
  m2_upArrow,
  m2_rgtArr,
  new_m2_rgtArow,
  new_m2_rgtArr_blk,
  new_warning_icon,
  notification,
  m2_search,
  right_Ar,
  down_ar,
  locked_symbol,
  close_btn,
  m2_slider1,
  m2_institute,
  m2_target,
  m2_rgtArow,
  m2_rgtArow1,
  m2_rgtArow2,
  new_m2_rgtArow,
  m2_bkmark,
  m2_bkNulTest,
  m2_bkNulExr,
  m2_bkNulDp,
  m2_bkVdo,
  m2_store,
  m2_video_ntF,
  new_m2_video_ntF,
  new_m2_test_ntF,
  m2_backBtn,
  m2_complete,
  m2_rank,
  m2_marks,
  m2_exit,
  m2_exit1,
  m2_language,
  m2_seeAll,

  motion_logo,
  motion_logo_svg,
  motion_logo_new,
  navonmesh_logo,
  homepage_2,
  homepage_3,
  homepage_4,
  homepage_5,
  homepage_6,
  homepage_7,
  homepage_9,
  expert_1,
  expert_2,
  expert_3,
  homepage_10,
  homepage_11,
  homepage_12,
  homepage_13,
  homepage_14,
  homepage_15,
  homepage_16,
  homepage_17,
  homepage_18,
  homepage_20,
  login_1,
  login_2,
  login_3,
  signUp_1,
  signUp_2,
  signUp_3,
  signUp_4,
  active_home,
  active_exercise,
  active_doubts,
  active_analytics,
  active_store,
  active_bookmark,
  active_transaction,
  inactive_home,
  inactive_exercise,
  inactive_doubts,
  inactive_analytics,
  inactive_store,
  inactive_bookmark,
  inactive_transaction,
  header_1,
  header_2,
  header_3,
  header_4,
  header_5,
  header_6,
  header_7,
  header_8,
  dummy_user_img,
  user_dashboard_1,
  user_dashboard_2,
  user_dashboard_3,
  user_dashboard_4,
  user_dashboard_5,
  user_dashboard_6,
  user_profile_1,
  user_profile_3,
  user_activity_1,
  user_activity_2,
  user_activity_3,
  user_activity_4,
  user_activity_bookmark_on,
  user_activity_bookmark_off,
  user_activity_5,
  no_data,
  user_activity_6,
  user_activity_7,
  user_activity_8,
  user_activity_back,
  user_activity_calendar,
  user_dashboard_7,
  user_dashboard_8,
  user_dashboard_9,
  user_chat_1,
  user_chat_2,
  user_chat_3,
  user_activity_9,
  user_activity_10,
  user_activity_11,
  user_activity_12,
  evaluation_1,
  user_activity_13,
  new_user_activity_13,
  user_activity_grid,
  user_activity_grid1,
  user_activity_list,
  user_activity_list1,
  user_activity_14,
  user_activity_14_1,
  evaluation_2,
  evaluation_3,
  evaluation_4,
  user_dashboard_10,
  user_dashboard_11,
  coin_welcom,
  coins,
  rocket_logo,
  new_coupon,
  slider_arrow,
  store_motion_coins,
  store_box,
  thumbs_up,

  // online test series images
  user_activity_15,
  user_activity_16,
  user_activity_17,
  user_activity_18,
  new_user_activity_18,
  user_activity_19,
  user_activity_20,
  user_activity_21,
  user_activity_22,
  user_activity_23,
  new_user_activity_23,
  user_activity_38,
  user_activity_56,
  user_activity_57,
  new_user_activity_57,
  user_activity_58,
  new_user_activity_58,
  user_activity_59,
  user_activity_60,

  // exam cracker images
  user_activity_24,
  user_activity_25,
  user_activity_26,

  // exam cracker images
  user_activity_30,
  user_activity_31,
  mock_test_bit_sat_1,

  user_activity_calendar,

  // store new
  user_activity_39,
  user_activity_38,
  user_activity_40,
  user_activity_41,
  user_activity_42,
  user_activity_43,
  user_activity_44,
  store_activiy_1,
  store_link,
  terms_and_condition,
  store_tick,
  new_store_tick,
  new_store_tick_list,
  store_offer,
  new_store_banner,
  info,
  store_traning,
  store_learning,
  store_report,
  store_e_learning,
  store_video_sol,
  store_analytics,
  amrit_gurantee,

  // refund

  refund_submit,
  refund_img,

  //question bank
  user_activity_32,
  user_activity_33,
  user_activity_34,
  user_activity_35,
  user_activity_36,
  new_user_activity_36,
  user_activity_37,
  new_user_activity_37,

  // adaptive cps
  user_activity_61,
  // user_activity_45,
  // user_activity_46,

  //////////////

  //help and support

  chatSendIcon,
  new_chatSendIcon,
  helpAndSupport,
  moitonchatLogo,

  /// SPR
  user_activity_45,
  user_activity_46,
  user_activity_46_1,
  user_activity_47,
  user_activity_48,
  user_activity_48_1,
  user_activity_49,
  user_activity_50,
  user_activity_51,
  user_activity_52,
  user_activity_53,
  user_activity_54,
  user_activity_54_1,
  logout,
  user_activity_55,
  user_activity_62,
  user_activity_63,
  user_activity_64,
  user_activity_65,
  /////// Adaptive CPS
  acps_banner,
  acps_banner_plus,

  //////

  physics_expert_1,
  physics_expert_2,
  physics_expert_3,
  physics_expert_4,
  physics_expert_5,
  physics_expert_6,
  physics_expert_7,
  /////
  chemistry_expert_1,
  chemistry_expert_2,
  chemistry_expert_3,
  chemistry_expert_4,
  chemistry_expert_5,
  chemistry_expert_6,
  chemistry_expert_7,
  chemistry_expert_8,
  chemistry_expert_9,
  ////
  mathemetics_expert_1,
  mathemetics_expert_2,
  mathemetics_expert_3,
  mathemetics_expert_4,
  mathemetics_expert_5,
  ////
  biology_expert_1,
  biology_expert_2,
  //
  backBtn,
  backBtn1,
  motionCoin,
  new_motionCoin,
  new_motionCoin1,
  motionWalletNew,
  motionWallet,
  transactions,
  new_transactions,
  motionCoinsStore,
  //

  uploadFile,

  //popups

  logOut,
  prohibition,
  warning,
  profile,
  MutipleLogin,

  //common banner for examcracker, CPS and ACPS
  commonBannerLogo,
  commonBannerCircle,
  commonBannerLogoCps,

  //new live streaming

  chatIcon,
  sendBtn,
  emojiIcon,
  eyeView,
  greaterThan,
  ratingIcon,
  nvSirProPic,
  motionLogo,
  pollLogo,
  pollIcon,

  BookmarkBtn,
  BookmarkBtn1,

  exploreCourse,
  arrow,

  // new landing page

  newLandingBg,
  landingPageLogo,
  callIcon,
  jee,
  neet,
  foundation,
  cuet,
  NvSir,
  SpinImg,
  benifitsCommonImg,
  doubtsImg,
  practiceImg,
  reportImg,
  right,
  pkg1,
  pkg2,
  pkg3,
  news1,
  news2,
  news3,
  playStore,
  loginPageImg,
  whyMotionBanner,
  studentReviewIcon,
  leftArrowCircle,
  rightArrowCircle,
  contactUsBanner,
  mobileContactUsBanner,
  loginCloseBtn,
  nextImg,
  tick,
  noReferals,
  new_motionCoin,
  new_motionCoin1,
  new_transactions,
  nv_sir,
  learningPerformance,
  coupon,
  phInfo,
  storeBanner,
  whatsaap,
  phoneCall,

  // watch free videos
  watchFreeVideos,
  watchFreeVideoIcon,
  watchFreeVideoArrow,

  // refer and earn

  cashIcon,
  copyIcon,
  checkBoxTickIcon,
  exclamationIcon,
  newBackBtn,
  successfulIcon,
  invalidlIcon,
  whatsappIcon,
  shareIcon,
  dropDownIcon,
  noReferals,
  new_motionCoin,
  new_motionCoin1,
  new_transactions,
  leftArrow,

  coinImg,
  storeBck,
  storeRightArrow,

  // parent

  studentPic,
  expectedIcon,
  allIndiaIcon,
  yourMarksIcon,
  topperMarksIcon,
  parentNoDataIcon,
  plusIcon,
  parentRightIcon,
  bookmark_img,
  unattempted,

  //handbook

  locationIcon,
  handbookCart,
  handbookDelete,
  handbookEmptyCart,
  handbookOrderSuccess,
  handbookIncrease,
  handbookDecrease,
  handbookCartEmptyImg,
  handbookImg,
  handbookOrderFailed,
  hamburg,
  homeImg,
  StoreVector,
  bookmarkVector,
  questionVector,
  doubtsVector,
  practiceVector,
  infosvg,
  infopng,
  homepageBanner,
};

export default images;
